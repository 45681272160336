import React, { useState  } from 'react';
import Notification from './Notification/Notification';

import './Notifications.css';


export default function Notifications() {

    const [notifications, setNotifications] = useState([]
    );


    /*
    const ws = new WebSocket('ws://localhost:8765');
    

    ws.onopen = () => {
        // on connecting, do nothing but log it to the console
        console.log('connected');
    }

    ws.onclose = () => {
        console.log('disconnected');
        // automatically try to reconnect on connection loss
        ws.close();

    }

    ws.onmessage = (evt) => {
        // listen to data sent from the websocket server
        const message = JSON.parse(evt.data);
        console.log("message got", message);
        addNotification(message);
    }

*/


   
    function removeNotification(item) {
        const items = [...notifications];

        /*
        for (var i = 0; i < notifications.length; i++) {
            if (items[i].id === item.id) {
                items.splice(i, 1);
                console.log(items);
                setNotifications(items);
                break;
            }
        }
        */
    }

    function addNotification(item) {
        const items = [...notifications, item];
        setNotifications(items);
    }



    return <div className="notification-container">
        {
            notifications.map((x) => {
                return <Notification data={x} onHideCallback={ removeNotification }/>
            })
        }
    </div>
}