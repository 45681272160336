import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Login.css';
import { Button, Form, FormGroup, Label, Input, Spinner, Alert } from 'reactstrap';
import ReactGA from 'react-ga';
ReactGA.pageview(window.location.pathname + window.location.search);
export default function Login({ SetToken }) {


    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [authError, setAuthError] = useState(false, ResetMessage);
    const [isLoading, setLoading] = useState(false);


    function ResetMessage() {
        setTimeout(() => setAuthError(false), 5000);
    }

    function validate(username, password) {
        setLoading(true);
        var r = fetch('/Authorization/Login', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ username, password })
        }).then(response => {
            setLoading(false);
            switch (response.status) {
                case 200:
                    response.json().then(d => {
                        SetToken(d.value.token);
                    });
                    break;
                case 401:
                    FadingAlert();
                    break;
            }

        })
    }

    function FadingAlert() {
        setAuthError(true);
        setTimeout(() => setAuthError(false), 5000);
    }

    const handleSubmit =  e => {
        e.preventDefault();
         validate(
            username,
            password
        );
    }

    return <div className="pageWrapper">
        <div className="login-wrapper">
            <h1> Log In</h1>
            <Form onSubmit={handleSubmit}>
                <FormGroup>
                    <Label >
                        <p>Username</p>
                    </Label >
                    <Input type="text" onChange={e=> setUserName(e.target.value) }/>
                </FormGroup>
                <FormGroup>
                    <Label >
                        <p>Password</p>
                    </Label >
                    <Input type="password" onChange={e => setPassword(e.target.value)}></Input>
                </FormGroup>
                <br/>
                <div>
                    <Alert color="danger" isOpen={authError}>Invalid Credentials</Alert>
                    {isLoading ? <Spinner color="primary"/> :
                        <Button color="primary" type="submit">Login</Button>
                    }
                </div>
            </Form>
            </div>

        </div>

}

Login.propTypes = {
    Validate: PropTypes.func,
}