import React, { useState } from 'react';
import Toast from 'react-bootstrap/Toast';



export default function Notifications({ data, onHideCallback }) {

    const [show, setShow] = useState(true);


    function HandleClose() {
        setShow(false);
        onHideCallback(data);
    }


    return <div >
        <Toast key={data.id} animation show={show} onClose={HandleClose} autohide>
            <Toast.Header>
                <strong className="mr-auto">{data.title}</strong>
                <small>just now</small>
            </Toast.Header>
            <Toast.Body> {data.content} </Toast.Body>
        </Toast>
    </div>
}