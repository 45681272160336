import React, { Component, useEffect, useState } from 'react';
import '../main.css'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';


export function Home() {
    //static displayName = "Denny Sabu";

    const [submitting, setSubmitting] = useState(false);    

    function handleSubmit(event) {
        setSubmitting(true);
        event.preventDefault();

        var name = document.getElementById('comment-name').value;
        var email = document.getElementById('comment-email').value;
        var comment = document.getElementById('comment-comment').value;

        document.getElementById('comment-name').value = null
        document.getElementById('comment-email').value = null
        document.getElementById('comment-comment').value = null

        fetch('/Comment/SubmitComment', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'Name': name,
                'Email': email,
                'Comment': comment
            })
        }).then(() => { setTimeout(() =>setSubmitting(false), 3000) });
    }

    return (<div className="main-website">
        <div className="panel-1">
            <div className="title-text-content">
                <div className="title-name">
                    Denny Sabu
                </div>
                <div> Software Engineer </div>
            </div>

        </div>
        <div className="panel-2">
            <hr style={{ width: '80%' }}/>
            <h3>Wanna get in touch? </h3>
            <div>
                <div style={{ width: '90%', margin: 'auto' }}>
                    You can use the following prompt to send me a message.
                </div>

                <div className='contact'>
                    <Form onSubmit={handleSubmit}>
                        <Form.Label>Your Name</Form.Label>
                        <Form.Control id="comment-name" type="text" placeholder="Enter your name" /> 
                        <Form.Label>Email address</Form.Label>
                        <Form.Control id="comment-email" type="email" placeholder="Enter email" required/>
                        <Form.Label>Comment</Form.Label>
                        <Form.Control id="comment-comment" as="textarea" rows={3} required/>
                        <div className='centered-button'>

                            <Button variant="primary" type="submit">
                                { submitting ? '✅' : 'Submit'}
                            </Button>
                            
                            
                        </div>
                        
                    </Form>
                 </div>
            </div>
            </div>
    </div>
        
    );
}
