import React  from 'react';
import { Route, Switch } from 'react-router-dom';
import { Home } from './components/Home';
import { PageNotFound } from './components/PageNotFound';
import Sleep from './components/Sleep/Sleep';
import Statistics from './components/Statistics/Statistics';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import useToken from './components/useToken';


import Privacy from './components/Privacy';
import Notifications from './components/Notifications/Notifications';

import './custom.css';

export default function App() {

    const { token, setToken } = useToken();

    return (<div className='pageContentWrapper'>
        <Notifications />
        { <Home />}
    </div>);
}

