import React from 'react';
import './PageNotFound.css';

export class PageNotFound extends React.Component {

    handleMouseMove = (evt) => {
        const card = document.querySelector(".cheeky-text");
        const cardRect = card.getBoundingClientRect();

        
            const x = (evt.clientX - cardRect.left) / cardRect.width;
            const dx = -(x - 0.5);
            const dxNorm = Math.min(Math.max(dx, -0.5), 0.5);
            const y = (evt.clientY - cardRect.top) / cardRect.height - 0.5;
            //const yNorm = Math.min(Math.max(y, 0), 1);
            card.style.transform = `perspective(1000px) rotateY(${
                dxNorm * 45
                }deg) rotateX(${y * 45}deg) `;

    }

    render() {
        return (<div className="default-not-found">
            <div className="not-found-container">
                <div className="space stars1"></div>
                <div className="space stars2"></div>
                <div className="space stars3"></div>

                <div className="cheeky-text" onMouseMove={this.handleMouseMove} onMouseLeave={() => {
                    const card = document.querySelector(".cheeky-text");
                    card.style.transform = "none";
                }}>
                    <p>Looks like you're a little lost.<br /> I guess it was a good thing I didn't skip<br /> writing a 404 page.</p>
                </div>
            </div>
        </div>);
    }

}